import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Spinner from 'components/common/Spinner';
import styles from './LoadingComponentPlaceholder.module.scss';

const LoadingComponentPlaceholder = ({ textPlaceholder, style }) => {
  return (
    <div className={cx(styles.container, { [styles.textPlaceholder]: textPlaceholder })} style={style}>
      <Spinner classNames={{ [styles.spinnerCentered]: true, [styles.textPlaceholder]: textPlaceholder }} size={10} />
    </div>
  );
};

LoadingComponentPlaceholder.defaultProps = {
  textPlaceholder: false,
  style: {}
};

LoadingComponentPlaceholder.propTypes = {
  textPlaceholder: PropTypes.bool,
  style: PropTypes.shape({})
};

export default LoadingComponentPlaceholder;
