import React from 'react';
import PropTypes from 'prop-types';
import Pricing from 'components/common/Pricing';
import cx from 'classnames';
import LoadingComponentPlaceholder from 'components/common/LoadingComponentPlaceholder';
import styles from './PreppedAndReadyPricing.module.scss';

const PreppedAndReadyPricing = ({ price, maxShipping, isLoading }) => {
  const isDiscountedShipping =
    parseInt(price.shipping, 10) < parseInt(maxShipping, 10) ||
    (price.introductoryShippingWithDiscount !== '' &&
      parseInt(price.introductoryShippingWithDiscount, 10) < parseInt(maxShipping, 10));

  return (
    <div className={styles.planPricingContainer}>
      <div className={styles.pricesRow}>
        <div className={styles.priceAmountBox}>
          <div className={styles.pricingRow}>
            <div
              className={cx(styles.priceAmount, { [styles.discounted]: price.unitPriceWithDiscount })}
              data-testid="serving-price-value"
            >
              {isLoading ? (
                <LoadingComponentPlaceholder style={{ height: '16px', width: '58px' }} textPlaceholder={true} />
              ) : (
                <Pricing isUpdatedPlanSelector price={price.unitPriceWithDiscount || price.serving} />
              )}
            </div>
            <span className={styles.pricingLabel}>per serving</span>
          </div>
          <div>
            {price.totalWithDiscount && !price.isExternalPromotion && (
              <div className={styles.discountedPrice} data-testid="serving-price-value-with-discount">
                <Pricing isUpdatedPlanSelector price={price.serving} transform={true} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.priceAmountBox}>
          <div className={styles.pricingRow}>
            <div className={styles.priceAmount} data-testid="shipping-price-value">
              {isLoading ? (
                <LoadingComponentPlaceholder style={{ height: '16px', width: '58px' }} textPlaceholder={true} />
              ) : (
                <Pricing
                  isUpdatedPlanSelector
                  price={price.introductoryShippingWithDiscount || price.shipping}
                  transform={true}
                />
              )}
            </div>
            <span className={styles.pricingLabel}>shipping</span>
          </div>
          <div>
            {isDiscountedShipping && (
              <div className={styles.discountedPrice} data-testid="max-shipping-price-value">
                <Pricing isUpdatedPlanSelector price={maxShipping} transform={true} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.externalPromotionBox}>
        {price.isExternalPromotion && (
          <div className={styles.pricingRow}>
            <p className={styles.discountCopy} data-testid="external-promotion-copy">
              {price.discountDescription}
            </p>
            <div>
              <p>Applied at checkout</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

PreppedAndReadyPricing.propTypes = {
  price: PropTypes.shape({
    serving: PropTypes.string,
    shipping: PropTypes.string,
    total: PropTypes.string,
    totalWithDiscount: PropTypes.string,
    unitPriceWithDiscount: PropTypes.string,
    introductoryShippingWithDiscount: PropTypes.string,
    isExternalPromotion: PropTypes.bool,
    discountDescription: PropTypes.string
  }).isRequired,
  isLoading: PropTypes.bool,
  maxShipping: PropTypes.string.isRequired
};

PreppedAndReadyPricing.defaultProps = {
  isLoading: false
};

export default PreppedAndReadyPricing;
