import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import QuantitySelector from 'components/common/QuantitySelector';
import Button from 'components/common/Button';

import {
  getDesktopPlanVariations,
  getTotalWithDiscount,
  getUnitPriceWithDiscount,
  getIntroductoryShippingPriceWithDiscount,
  getMaxShipping,
  getPreparedAndReadyPlanVariation,
  getMealKitPlanVariations
} from 'shared/utils/plans';
import { MEAL_KITS_SUBTITLE, PREPPED_AND_READY_SUBTITLE, PREPPED_AND_READY_PLAN } from 'shared/constants/on-the-menu';

import { validFoodPlanVariation } from 'shared/prop-types/plans';
import PreppedAndReadyPricing from 'components/common/planSelectors/PreppedAndReadyPricing/PreppedAndReadyPricing';
import LoadingComponentPlaceholder from 'components/common/LoadingComponentPlaceholder';
import styles from './PreppedAndReadyMiniPlanSelector.module.scss';

const PreppedAndReadyMiniPlanSelector = ({
  selectedPlanVariation,
  selectedPlanQuantity,
  isLoading,
  coupon,
  planVariations,
  onSelectPlan,
  onSelectMealQuantity,
  onContinueButtonClick
}) => {
  const [preppedAndReadySelected, setPreppedAndReadySelected] = useState(
    selectedPlanVariation?.display?.planApiName === PREPPED_AND_READY_PLAN
  );

  useEffect(() => {
    setPreppedAndReadySelected(selectedPlanVariation?.display?.planApiName === PREPPED_AND_READY_PLAN);
  }, [selectedPlanVariation]);

  const desktopPlanVariations = getDesktopPlanVariations(planVariations, selectedPlanVariation);
  const preppedAndReadyPlan = getPreparedAndReadyPlanVariation(desktopPlanVariations);
  const mealKitPlans = getMealKitPlanVariations(desktopPlanVariations);
  const maxShipping = getMaxShipping(planVariations);
  const pricingDataForDisplay = {
    serving: '',
    shipping: '',
    ...selectedPlanVariation?.options[selectedPlanQuantity]?.price,
    totalWithDiscount: getTotalWithDiscount(coupon, selectedPlanVariation?.id, selectedPlanQuantity) || '',
    unitPriceWithDiscount: getUnitPriceWithDiscount(coupon, selectedPlanVariation?.id, selectedPlanQuantity) || '',
    introductoryShippingWithDiscount:
      getIntroductoryShippingPriceWithDiscount(coupon, selectedPlanVariation?.id, selectedPlanQuantity) || '',
    discountDescription: (!!coupon && !!coupon.discountDescription && coupon.discountDescription) || '',
    isExternalPromotion: coupon && !coupon.discount && !!coupon.discountDescription
  };

  // This selector component is designed to only identify plans by # of servings.
  // This function ensures a quantity is only mapped to a single plan, identifiable by that quantity.
  const planVariationsByNumberOfServings = mealKitPlans.reduce(
    (acc, variation) =>
      acc[variation.serves]
        ? acc
        : {
            ...acc,
            [variation.serves]: variation
          },
    {}
  );

  const handleSelectServingsQuantity = (selectedQuantity) => {
    const selectedPlan = planVariationsByNumberOfServings[selectedQuantity];

    onSelectPlan(
      {
        id: selectedPlan.id,
        isVegetarian: selectedPlan.isVegetarian,
        planApiName: selectedPlan.planApiName
      },
      selectedPlanQuantity
    );
  };

  const handleSelectRecipesQuantity = (selectedQuantity) => {
    onSelectMealQuantity(selectedQuantity, selectedPlanVariation?.id);
  };

  const handleSubmitContinue = () => {
    onContinueButtonClick({
      id: selectedPlanVariation?.id,
      isVegetarian: selectedPlanVariation?.display.isVegetarian,
      planApiName: selectedPlanVariation?.display.planApiName,
      quantity: selectedPlanQuantity,
      planType: selectedPlanVariation?.id,
      planName: selectedPlanVariation?.display.displayNameVariation || selectedPlanVariation?.displayName,
      price: selectedPlanVariation?.options[selectedPlanQuantity].price.total
    });
  };

  const mealQuantitySelectorOptions = Object.keys(selectedPlanVariation?.options || {});
  const servingQuantitySelectorOptions = Object.keys(planVariationsByNumberOfServings || {});

  const handleSelectMealKits = () => {
    // toggle back to first plan and options available
    const firstMealKitPlan = mealKitPlans[0] || {};

    onSelectPlan(
      {
        id: firstMealKitPlan.id,
        isVegetarian: firstMealKitPlan.isVegetarian,
        planApiName: firstMealKitPlan.planApiName
      },
      2
    );
    setPreppedAndReadySelected(false);
  };

  const handleSelectPreppedAndReady = () => {
    onSelectPlan({
      id: preppedAndReadyPlan.id,
      isVegetarian: preppedAndReadyPlan.isVegetarian,
      planApiName: preppedAndReadyPlan.planApiName
    });
    setPreppedAndReadySelected(true);
  };

  return (
    <>
      <section className={styles.planSelectorContainer} id="prepped-and-ready-mini-plan-selector">
        <div className={styles.planSelectorWrapper}>
          <div className={styles.planSelectorTopRow}>
            <div className={styles.planListTitle}>
              <h1>Build your plan</h1>
            </div>
            {isLoading ? (
              <LoadingComponentPlaceholder style={{ height: '40px', marginBottom: '16px', borderRadius: '6px' }} />
            ) : (
              <ul className={styles.planTypeSelector} data-selected={preppedAndReadySelected ? 1 : 0}>
                <li className={!preppedAndReadySelected ? styles.selectedPlanType : ''}>
                  <button type="button" onClick={handleSelectMealKits}>
                    Meal Kits
                  </button>
                </li>
                <li className={preppedAndReadySelected ? styles.selectedPlanType : ''}>
                  <button type="button" onClick={handleSelectPreppedAndReady}>
                    Prepared & Ready
                  </button>
                </li>
              </ul>
            )}
            <small className={styles.subtitle}>
              {preppedAndReadySelected ? PREPPED_AND_READY_SUBTITLE : MEAL_KITS_SUBTITLE}
            </small>
          </div>
          <div className={styles.planSelectorMiddleRow}>
            {preppedAndReadySelected ? (
              <div className={styles.pnrServingInformation}>
                <div className={styles.pnrServingTitle}>Servings per meal</div>
                <div className={styles.quantitySize}>Single serving meals</div>
              </div>
            ) : (
              <>
                <div className={styles.quantityTitle}>Servings per meal</div>
                <div className={styles.quantitySelector} style={{ marginBottom: '16px' }}>
                  <div data-testid="plan-selector">
                    {isLoading ? (
                      <LoadingComponentPlaceholder style={{ height: '38px', borderRadius: '6px' }} />
                    ) : (
                      <QuantitySelector
                        uit="pnr-plan-card-servings-quantity-selector"
                        quantities={servingQuantitySelectorOptions}
                        selected={JSON.stringify(selectedPlanVariation?.product?.serves)}
                        onSelect={handleSelectServingsQuantity}
                        type="block-rounded"
                        fullWidth={true}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
            <div className={styles.quantityTitle}>Meals per week</div>
            <div className={styles.quantitySelector} style={{ marginBottom: '24px' }}>
              <div data-testid="meals-selector">
                {isLoading ? (
                  <LoadingComponentPlaceholder style={{ height: '38px', borderRadius: '6px' }} />
                ) : (
                  <QuantitySelector
                    uit="pnr-plan-card-meals-quantity-selector"
                    quantities={mealQuantitySelectorOptions}
                    onSelect={handleSelectRecipesQuantity}
                    selected={selectedPlanQuantity}
                    type="block-rounded"
                    fullWidth={true}
                  />
                )}
              </div>
            </div>
            <PreppedAndReadyPricing price={pricingDataForDisplay} maxShipping={maxShipping} isLoading={isLoading} />
          </div>
        </div>
        <div className={styles.planSelectorBottomRow}>
          <div className={styles.continueButton}>
            <Button
              id="continue-btn"
              size="card-md"
              buttonType="pill-filled"
              styles={{ borderRadius: '24px', width: '100%' }}
              text="continue"
              isDisabled={isLoading}
              onClick={handleSubmitContinue}
            />
          </div>
        </div>
      </section>
      <div className={styles.skipSubtitleContainer}>
        <span className={styles.subtitle}>Skip, pause, or cancel at any time.</span>
      </div>
    </>
  );
};

PreppedAndReadyMiniPlanSelector.propTypes = {
  onSelectPlan: PropTypes.func.isRequired,
  onSelectMealQuantity: PropTypes.func.isRequired,
  selectedPlanVariation: validFoodPlanVariation,
  selectedPlanQuantity: PropTypes.string.isRequired,
  planVariations: PropTypes.arrayOf(validFoodPlanVariation).isRequired,
  coupon: PropTypes.shape({
    code: PropTypes.string.isRequired,
    discountDescription: PropTypes.string,
    discount: PropTypes.string,
    prices: PropTypes.arrayOf(
      PropTypes.shape({
        planId: PropTypes.string.isRequired,
        productsPerOrder: PropTypes.string.isRequired,
        introductory: PropTypes.arrayOf(PropTypes.string).isRequired
      })
    ),
    restrictions: PropTypes.shape({
      card: PropTypes.bool
    })
  }),
  isLoading: PropTypes.bool,
  onContinueButtonClick: PropTypes.func.isRequired
};

PreppedAndReadyMiniPlanSelector.defaultProps = {
  coupon: null,
  isLoading: false,
  selectedPlanVariation: null
};

export default PreppedAndReadyMiniPlanSelector;
