export const menuTypes = [
  { name: 'Signature', servingSize: 2 },
  { name: 'Vegetarian', servingSize: 2 },
  { name: 'Wellness', servingSize: 2 },
  { name: 'Signature for Four', servingSize: 4 },
  { name: 'Add-Ons' }
];

export const planTypes = {
  two_person_plan: { name: 'Meal Kits', servingSize: '2 servings', sort: 1 },
  family_plan: { name: 'Meal Kits', servingSize: '4 servings', sort: 2 },
  prepped_and_ready_plan: { name: 'Prepared & Ready', servingSize: '1 serving', sort: 3 },
  add_ons_plan: { name: 'Add-ons', servingSize: 'Servings vary', sort: 4 }
};

export const planTypeDescriptions = {
  family_plan: {
    crossMenu: 'Easy-to-follow recipes perfect for families, get-togethers, or having leftovers.',
    default: 'An ever-changing mix of easy-to-follow recipes perfectly portioned for four.'
  },
  two_person_plan: {
    crossMenu: 'Easy-to-follow recipes perfectly portioned for two.',
    default: 'An ever-changing mix of easy-to-follow recipes perfectly portioned for two.'
  },
  prepped_and_ready_plan: {
    crossMenu: 'Top-quality pre-made meals delivered fresh and done in minutes.',
    default: `Top-quality pre-made meals delivered fresh, ready in minutes, and starting at just $9.99.`
  },
  add_ons_plan: {
    crossMenu: 'Smaller bites like appetizers, sides, dessert, and breakfast.',
    default: `Complete your meal with appetizers and sides or add even more Blue Apron to your day with breakfast and dessert options.`
  }
};

export const menuTypeDescriptions = {
  Signature: 'Choose from an ever-changing mix of meat, fish, and health-conscious offerings.',
  Vegetarian: 'Meat-free dishes that celebrate the best of seasonal produce.',
  'Signature for Four':
    'Recipes for families, get-togethers, or left-overs, with a variety of options that change weekly, including meat, fish and other health-conscious offerings.',
  Wellness: 'Balanced, nutritionist-approved recipes designed for your holistic health.',
  'Add-Ons':
    'Complete your meal with appetizers and sides or add even more Blue Apron to your day with breakfast and dessert options.'
};

export const TWO_PERSON_PLAN = 'two_person_plan';
export const FAMILY_PLAN = 'family_plan';
export const ADD_ONS_PLAN = 'add_ons_plan';
export const PREPPED_AND_READY_PLAN = 'prepped_and_ready';

export const MEAL_KITS_SUBTITLE = 'Ingredients and easy-to-follow recipes for home-cooked meals';
export const PREPPED_AND_READY_SUBTITLE = `Top-quality pre-made meals delivered fresh, ready in minutes, and starting at just $9.99.`;
